import { useFormik } from 'formik'
import { useWindowSize } from 'hooks'
import api from '@services/api'
import * as yup from 'yup'
import { useState } from 'react'

const validationSchema = yup.object().shape({
  firstName: yup.string().required('Please enter an first name.'),
  lastName: yup.string().required('Please enter an last name.'),
  email: yup
    .string()
    .email('Invalid email address')
    .required('Please enter an email address.'),
})

const validate = (values) => {
  const errors: { firstName?: string; lastName?: string; email?: string } = {}

  if (!values.firstName) {
    errors.firstName = 'Required'
  } else if (values.firstName.length > 15) {
    errors.firstName = 'Must be 15 characters or less'
  }

  if (!values.lastName) {
    errors.lastName = 'Required'
  } else if (values.lastName.length > 20) {
    errors.lastName = 'Must be 20 characters or less'
  }

  if (!values.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }

  return errors
}

const NewsLetter = ({ title }: { title: string | JSX.Element }) => {
  return (
    <>
      <div className="lg:w-[800px] w-[344px] lg:h-[284px] h-[435px]  bg-sky-500 rounded-[8px] m-auto mt-[-150px] lg:mt-[-180px]">
        <div className="h-[296px] lg:p-[32px] p-[32px_24px] flex lg:flex-row flex-col items-start lg:gap-[24px] gap-[16px]">
          <span className="font-knockout-71 font-[415] lg:text-[48px] text-[34px] lg:leading-[36px] leading-[24px] text-center uppercase text-night-800 ">
            {title}
          </span>
          <SignupForm />
        </div>
      </div>
    </>
  )
}

// TODO: Fix Validation errors/styling
const SignupForm = () => {
  const windowSize = useWindowSize()
  const pageWidth = windowSize?.width || 0

  const [signedUp, setSignedUp] = useState(false)
  const [emailAddress, setEmailAddress] = useState('')

  const handleSignUp = async (values) => {
    const { firstName, lastName, email } = values

    try {
      await api.signupNewsletter(email, firstName, lastName)
      setEmailAddress(email)
      setSignedUp(true)
    } catch (error) {
      setSignedUp(false)
      console.error(error)
    }
  }

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
    validationSchema,
    onSubmit: handleSignUp,
    validateOnBlur: false,
    validateOnChange: true,
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div
        className={`${
          signedUp ? 'hidden ' : ''
        } lg:w-[333px] w-[296px] flex flex-col items-start  gap-[24px]`}
      >
        <div className=" flex flex-col items-start gap-[8px]">
          {pageWidth < 1024 ? (
            <>
              <div className="w-[296px] flex flex-col ">
                <label
                  htmlFor="firstName"
                  className="font-ringside-ssm-black font-[700] text-[13px]  text-grayscale-coal-900"
                >
                  First Name<span className="text-beef-500">*</span>
                </label>
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  className="h-[33px] box-border p-[10px] bg-grayscale-coal_white border border-solid border-grayscale-coal-300"
                ></input>
                {formik.touched.firstName && formik.errors.firstName ? (
                  <div className="flex font-ringside-ssm-black font-[700] text-[9px] text-beef-500">
                    {formik.errors.firstName}
                  </div>
                ) : null}
              </div>
              <div className="w-[296px] flex flex-col  ">
                <label
                  htmlFor="lastName"
                  className="font-ringside-ssm-black font-[700] text-[13px]  text-grayscale-coal-900"
                >
                  Last Name<span className="text-beef-500">*</span>
                </label>
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                  className="h-[33px] box-border p-[10px] bg-grayscale-coal_white border border-solid border-grayscale-coal-300666666^66"
                ></input>
                {formik.touched.lastName && formik.errors.lastName ? (
                  <div className="flex font-ringside-ssm-black font-[700] text-[9px] text-beef-500">
                    {formik.errors.lastName}
                  </div>
                ) : null}
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-row gap-[16px]">
                <div className="w-[158.5px] flex flex-col ">
                  <label className="font-ringside-ssm-black font-[700] text-[13px]  text-grayscale-coal-900">
                    First Name<span className="text-beef-500">*</span>
                  </label>
                  <input
                    id="firstName"
                    name="firstName"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                    className="h-[40px] box-border p-[10px] bg-grayscale-coal_white border border-solid border-grayscale-coal-300"
                  ></input>
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <span className="flex font-ringside-ssm-black font-[700] text-[9px] text-beef-500">
                      {formik.errors.firstName}
                    </span>
                  ) : null}
                </div>
                <div className="w-[158.5px] flex flex-col  ">
                  <label className="font-ringside-ssm-black font-[700] text-[13px]  text-grayscale-coal-900">
                    Last Name<span className="text-beef-500">*</span>
                  </label>
                  <input
                    id="lastName"
                    name="lastName"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName}
                    className="h-[40px] box-border p-[10px] bg-grayscale-coal_white border border-solid border-grayscale-coal-300"
                  ></input>
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <span className="flex font-ringside-ssm-black font-[700] text-[9px] text-beef-500">
                      {formik.errors.lastName}
                    </span>
                  ) : null}
                </div>
              </div>
            </>
          )}

          <div className="lg:w-[333px] w-[296px] flex flex-col">
            <label className="font-ringside-ssm-black font-[700] text-[13px]  text-grayscale-coal-900">
              Email<span className="text-beef-500">*</span>
            </label>
            <input
              id="email"
              name="email"
              type="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              className="lg:h-[40px] h-[33px] box-border p-[10px] bg-grayscale-coal_white border border-solid border-grayscale-coal-300"
            ></input>
            {formik.touched.email && formik.errors.email ? (
              <span className="font-ringside-ssm-black font-[700] text-[9px] text-beef-500">
                {formik.errors.email}
              </span>
            ) : null}
          </div>
        </div>
        <button
          type="submit"
          className="lg:w-[333px] w-[296px] lg:h-[64px] h-[40px] p-[12px_16px] box-border flex flex-col justify-center items-center bg-grayscale-coal_white border-[2px] border-solid border-wave-500 uppercase font-ringside-compressed-ssm-black font-[900] lg:text-[20px] text-[16px] lg:leading-[20px] leading-[16px] text-center text-night-800 hover:bg-wave-500"
        >
          Sign Up
        </button>
      </div>
      <div
        className={`${
          !signedUp ? 'hidden ' : ''
        } lg:w-[333px] w-[296px] flex flex-col items-center  gap-[24px]`}
      >
        <span className="text-beef-500">
          The email address <strong>{emailAddress}</strong> has successfully
          been signed up for the Good Ranchers newsletter!
        </span>
      </div>
    </form>
  )
}

export default NewsLetter

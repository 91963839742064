import { useContext, useEffect, useState } from 'react'
import { GlobalContext } from 'contexts'
import { openDrawer } from 'services'
import { UserContext } from 'contexts/user-context'
import { UserActionType } from 'types/user-context'
import ShoppingCart from '@components/icons/ShoppingCart'

export default function CartIcon({ navbar }: { navbar: Boolean }) {
  const { dispatch: globalDispatch } = useContext(GlobalContext)
  const {
    state: { cart },
    dispatch: userDispatch,
  } = useContext(UserContext)

  const [totalItems, setTotalItems] = useState(0)

  useEffect(() => {
    userDispatch({ type: UserActionType.SET_CART, payload: cart })
    getTotalQuantity(cart)
  }, [cart, userDispatch])

  const getTotalQuantity = (cart) => {
    let totalQuantity = 0

    if (!cart) {
      setTotalItems(0)
      return
    }

    for (let key in cart?.line_items) {
      cart.line_items[key]
        .filter((item) => item.options && item.options.length > 0)
        .forEach((item) => {
          totalQuantity += item.quantity
        })
    }

    setTotalItems(totalQuantity)
    return totalQuantity
  }

  return (
    <div>
      <div onClick={() => globalDispatch(openDrawer())} className="relative hover:cursor-pointer">
        {!!totalItems ? (
          <ShoppingCart
            borderColor={navbar ? '#f1f0d8' : '#212721'}
            fillColor={navbar ? '#f1f0d8' : '#212721'}
          />
        ) : (
          <ShoppingCart
            borderColor={navbar ? '#f1f0d8' : '#212721'}
            fillColor={navbar ? '#BD472A' : '#f9f9f0'}
          />
        )}
        {!!totalItems && (
          <div className="bg-pork-500 rounded-full absolute flex flex-col  items-center justify-center lg:w-4 lg:h-4 lg:left-5  2xs:w-4 2xs:h-4 2xs:left-5 -top-1">
            <span className="font-ringside-compressed-ssm-bold text-2xs 2xs:text-3xs">
              {totalItems}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

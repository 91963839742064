import { UserContext } from 'contexts/user-context'
import { useWindowSize } from 'hooks'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useState } from 'react'
import CartIcon from '@components/cart/CartToggle'
import MainButton from './MainButton'
import BlogCart from './icons/BlogCart'
import { UserActionType } from 'types/user-context'

const mobileNavigation = () => {
  const windowSize = useWindowSize()
  const pageWidth = windowSize?.width || 0
  const [drawerOpen, setDrawerOpen] = useState(false)
  const { width: windowWidth } = useWindowSize()
  const { state } = useContext(UserContext)
  const [activeDropdown, setActiveDropdown] = useState(null)
  const router = useRouter()
  const closeDrawer = () => {
    setDrawerOpen(false)
    return undefined
  }
  const openDrawer = () => {
    setDrawerOpen(true)
    return undefined
  }

  const {
    state: { isSubscriber },
  } = useContext(UserContext)

  const { dispatch } = useContext(UserContext)

  useEffect(() => {
    windowWidth > 768 ? setDrawerOpen(false) : null
  })
  const [navItems, setNavItems] = useState({
    0: {
      text: 'About',
      path: '/about',
      show: true,
      content: null,
    },
    1: {
      text: 'Help',
      path: '/help-center',
      show: true,
      content: null,
    },
    2: {
      text: 'Blog',
      path: '/blog',
      show: true,
      content: null,
    },

    3: {
      text: 'Rewards',
      path: '/rewards',
      show: true,
      content: null,
    },

    4: {
      text: 'Shop Now',
      path: '/shop',
      show: true,
      content: [
        {
          text: 'Boxes',
          path: '/shop',
          show: true,
        },
        {
          text: 'Gifting',
          path: '/gifts',
          show: true,
        },
        {
          text: 'Merch',
          path: '/merch',
          show: true,
        },
        // {
        //   text: 'Add-ons',
        //   path: '/next-order-addons',
        //   show: !!isSubscriber ? true : false,
        // },
      ],
    },
    5: {
      text: 'Account',
      path: '/account',
      show: !!state.user,
      content: [
        {
          text: 'My Account',
          path: '/account',
          show: true,
        },
        {
          text: 'Orders',
          path: '/account/orders',
          show: true,
        },
        {
          text: 'My Boxes',
          path: '/account/subscriptions',
          show: true,
        },
        // {
        //   text: 'Wallet',
        //   path: '/account/wallet',
        //   show: true,
        // },
        {
          text: 'Rewards',
          path: '/account/loyalty',
          show: true,
        },
        {
          text: 'Settings',
          path: '/account/settings',
          show: true,
        },
        {
          text: 'Log out',
          path: '/login',
          show: true,
          // dispatch: UserActionType.LOGOUT,
        },
      ],
    },
    6: {
      text: 'Login',
      path: '/login',
      show: !!state.user ? false : true,
      content: null,
    },
  })

  useEffect(() => {
    const navItemsCopy = JSON.parse(JSON.stringify(navItems))

    navItemsCopy[4].content = navItemsCopy[4].content.map((contentItem) => {
      if (contentItem.text === 'Add-ons') {
        return {
          ...contentItem,
          show: !!isSubscriber,
        }
      }
      return contentItem
    })

    if (!!isSubscriber) {
      navItemsCopy[6].show = false
    } else {
      navItemsCopy[6].show = true
    }

    if (!!state.user) {
      navItemsCopy[6].show = false
      navItemsCopy[5].show = true
    } else {
      navItemsCopy[6].show = true
      navItemsCopy[5].show = false
    }

    setNavItems(navItemsCopy)
  }, [state.user, isSubscriber])

  const handleNavClick = (key, value) => {
    if (value.content) {
      setActiveDropdown(activeDropdown === key ? null : key)
    } else {
      router.push(value.path)
      closeDrawer()
    }
  }

  const handleShopNowClick = () => {
    router.push('/shop')
    closeDrawer()
  }

  return pageWidth > 768 ? (
    <nav>
      {/* Desktop */}
      <div
        hidden={pageWidth < 1024 ? true : false}
        className={`w-full h-[96px] pl-[20px] pr-[20px] md:pr-[30px] lg:pr-[50px] xl:px-[64px] flex fixed items-center justify-between ${
          window.location.href.toLocaleLowerCase().includes('blog')
            ? 'bg-beef-500'
            : 'bg-night-800'
        }  z-[3]`}
      >
        {/* Logo */}
        <div
          className="min-h-[48px] min-w-[96px] lg:min-h-[64px] lg:min-w-[192px] mr-[12px] hover:cursor-pointer flex justify-center items-center" // NOTE: negative margin is to account for the christmas logo with the hook on the ornament. Take off when reverting to original logo
          onClick={() => router.push('/shop')}
        >
          {window.location.href.toLocaleLowerCase().includes('blog') ? (
            <img
              className={`object-contain ${
                pageWidth < 850 ? 'h-[48px] items-center' : 'h-[64px]'
              } `}
              src="/img/rebrand/blogLogo.svg"
              alt="Good Ranchers Blog Logo"
            />
          ) : (
            <img
              className={`object-contain ${
                pageWidth < 926
                  ? 'items-center h-[48px] align-middle '
                  : 'h-[64px]'
              } `}
              src="/img/rebrand/FullLogo.svg"
              alt="Good Ranchers Logo"
            />
          )}
        </div>
        {/* NavBar Items */}
        <div className="h-[64px] flex flex-row items-center justify-end gap-[10px] md:gap-[9px] lg:gap-[15px]">
          {Object.entries(navItems).map(([key, value], index) => {
            return value.show ? (
              <div
                onClick={() => {
                  router.push(value.path)
                }}
                className={`flex flex-col hover:cursor-pointer items-center justify-center ${
                  router.pathname.toLowerCase() === value.path.toLowerCase()
                    ? 'decoration-[3px] underline underline-offset-4 decoration-yolk-500'
                    : 'no-underline'
                }`}
              >
                <span className="font-ringside-compressed-ssm-black font-[900] text-[16px] leading-[16px] lg:text-[18px] xl:text-[20px] lg:leading-[20px] text-center uppercase text-cream-500 hover:text-yolk-500">
                  {value.text}
                </span>
              </div>
            ) : null
          })}

          <MainButton text="Shop Now" />
          {router.pathname.toLowerCase().includes('/blog') ? (
            <BlogCart navbar={true} />
          ) : (
            <CartIcon navbar={true} />
          )}
        </div>
      </div>
    </nav>
  ) : (
    <>
      <div
        className={`w-full h-[48px] p-[8px_16px_8px_16px] flex flex-row fixed ${
          router.pathname.toLocaleLowerCase().includes('blog') ||
          router.pathname.toLocaleLowerCase().includes('pagetitle')
            ? 'bg-beef-500'
            : 'bg-night-800'
        } z-[90]`}
      >
        {/* Logo */}
        <div className="float-left" onClick={() => router.push('/')}>
          {' '}
          {/* NOTE: negative margin is to account for the christmas logo with the hook on the ornament. Take off when reverting to original logo */}
          {router.pathname.toLowerCase().includes('blog') ||
          router.pathname.toLocaleLowerCase().includes('pagetitle') ? (
            <img
              className="h-[32px]"
              src="/img/rebrand/blogLogo.svg"
              alt="Good Ranchers Blog Logo"
            />
          ) : (
            <img
              className="h-[32px]"
              src="/img/rebrand/FullLogo.svg"
              alt="Good Ranchers Logo"
            />
          )}
        </div>
        <span className="flex-grow"></span>
        <div className="mt-[6px]">
          <div className="h-[24px] flex flex-row gap-[12px] items-center justify-end p-0">
            {router.pathname.toLowerCase().includes('/blog') ? (
              <BlogCart navbar={true} />
            ) : (
              <CartIcon navbar={true} />
            )}

            <button
              className="bg-transparent border-0"
              type="button"
              id="toggle-drawer"
              onClick={openDrawer}
            >
              <img className="mb-[4px]" src="/img/rebrand/HamburgerMenu.svg" />
            </button>
          </div>
        </div>
      </div>

      {windowWidth <= 926 && (
        <div
          className="fixed top-0 w-screen md:w-3/4 right-0 h-screen overflow-scroll transform duration-500 translate-x-[100vw] bg-marbling-texture bg-beef-500 bg-cover bg-no-repeat"
          style={{
            transform: drawerOpen ? 'translateX(0)' : '',
            zIndex: 100,
          }}
        >
          <nav>
            <div className="w-full h-[48px] p-[12px_16px_8px_16px] flex flex-row">
              {/* Logo */}
              <div className="float-left" onClick={() => router.push('/shop')}>
                <img
                  className="h-[32px] "
                  src="/img/rebrand/FullLogo.svg"
                  alt="Good Ranchers Logo"
                  onClick={closeDrawer}
                />
              </div>

              <span className="flex-grow"></span>
              <div className="mt-[6px]">
                <div className="h-[24px] flex flex-row gap-[12px] items-center justify-end p-0">
                  <button
                    className="bg-transparent border-0"
                    type="button"
                    id="toggle-drawer"
                    onClick={closeDrawer}
                  >
                    <img
                      className="mb-[4px]"
                      src="/img/rebrand/menuClose.svg"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className="underline-navLogo"></div>
          </nav>

          <div className="flex flex-col w-[95%]  pl-4 gap-[10px] mt-[15px]">
            {Object.entries(navItems).map(([key, value]) => {
              return value.show ? (
                <div key={key}>
                  <div
                    onClick={() => handleNavClick(key, value)}
                    className={`w-full font-knockout-91 font-[415] text-[32px] leading-[24px] text-right uppercase pb-2 ${
                      value.text === 'Shop Now'
                        ? 'text-yolk-500'
                        : 'text-cream-500'
                    } active:text-yolk-500 flex justify-between items-center`}
                  >
                    {value.text === 'Shop Now' ? (
                      <span onClick={handleShopNowClick}>{value.text}</span>
                    ) : (
                      value.text
                    )}
                    {value.content && Array.isArray(value.content) && (
                      <img
                        src={
                          activeDropdown === key
                            ? '/img/rebrand/arrow-up.svg'
                            : '/img/rebrand/arrow-down.svg'
                        }
                        alt={activeDropdown === key ? 'Arrow Up' : 'Arrow Down'}
                        className="w-4 h-full"
                      />
                    )}
                  </div>

                  {value.content && Array.isArray(value.content) && (
                    <div
                      className={activeDropdown === key ? 'block' : 'hidden'}
                    >
                      {value.content.map((contentItem, contentIndex) => {
                        return contentItem.show ? (
                          <span
                            key={`content-${contentIndex}`}
                            onClick={() => {
                              if (contentItem.text === 'Log out') {
                                dispatch({ type: UserActionType.LOGOUT })
                              } else {
                                router.push(contentItem.path)
                              }
                              closeDrawer()
                            }}
                            className="w-full flex flex-row font-knockout-91 font-[415] text-[20px] leading-[35px] text-right uppercase text-cream-500 active:text-yolk-500"
                          >
                            {contentItem.text}
                          </span>
                        ) : null
                      })}
                    </div>
                  )}
                  <div className="underline-navItem "></div>
                </div>
              ) : null
            })}
          </div>
        </div>
      )}
    </>
  )
}

export default mobileNavigation

import Link from 'next/link'

const MainButton = ({ text }) => {
  return (
    <Link href="/shop">
      <button className="flex text-center flex-nowrap justify-center items-center p-[10px] font-ringside-compressed-ssm-black text-[16px] leading-[16px] lg:text-[20px] lg:leading-[20px] text-darkBlue bg-yolk-500 uppercase border-3 border-yolk-500  border-solid	hover:bg-yolk-300 hover:border-yolk-300 hover:cursor-pointer active:border-yolk-300 active:bg-yolk-300 no-underline">
        {text}
      </button>
    </Link>
  )
}

export default MainButton

import { UserContext } from 'contexts/user-context'
import { useWindowSize } from 'hooks'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useState } from 'react'
import CartIcon from '@components/cart/CartToggle'
import MainButton from './MainButton'
import BlogCart from './icons/BlogCart'

const Navigation = () => {
  const windowSize = useWindowSize()
  const pageWidth = windowSize?.width || 0
  const [drawerOpen, setDrawerOpen] = useState(false)
  const { width: windowWidth } = useWindowSize()
  const { state } = useContext(UserContext)
  const router = useRouter()
  const closeDrawer = () => {
    setDrawerOpen(false)
    return undefined
  }
  const openDrawer = () => {
    setDrawerOpen(true)
    return undefined
  }

  const {
    state: { isSubscriber },
  } = useContext(UserContext)

  useEffect(() => {
    windowWidth > 768 ? setDrawerOpen(false) : null
  })
  const [navItems, setNavItems] = useState({
    0: {
      text: 'About',
      path: '/about',
      show: true,
    },
    1: {
      text: 'Help',
      path: '/help-center',
      show: true,
    },
    2: {
      text: 'Blog',
      path: '/blog',
      show: true,
    },

    3: {
      text: 'Rewards',
      path: '/rewards',
      show: true,
    },
    4: {
      text: 'Merch',
      path: '/merch',
      show: true,
    },
    5: {
      text: 'Gifting',
      path: '/gifts',
      show: true,
    },
    6: {
      text: 'Login',
      path: '/login',
      show: !!state.user ? false : true,
    },
    // 7: {
    //   text: 'Add-ons',
    //   path: '/next-order-addons',
    //   show: !!isSubscriber ? true : false,
    // },
    8: {
      text: 'Account',
      path: '/account/orders',
      show: !!state.user ? true : false,
    },
  })

  useEffect(() => {
    const navItemsCopy = { ...navItems }

    // if (!!isSubscriber) {
    //   navItemsCopy[7].show = true
    // } else {
    //   navItemsCopy[7].show = false
    // }

    if (!!state.user) {
      navItemsCopy[6].show = false
      navItemsCopy[8].show = true
    } else {
      navItemsCopy[6].show = true
      navItemsCopy[8].show = false
    }

    setNavItems({
      ...navItems,
      ...navItemsCopy,
    })
  }, [state.user])

  return pageWidth > 768 ? (
    <nav>
      {/* Desktop */}
      <div
        hidden={pageWidth < 1024 ? true : false}
        className={`w-full h-[96px] pl-[20px] pr-[20px] md:pr-[30px] lg:pr-[50px] xl:px-[64px] flex fixed items-center justify-between ${
          window.location.href.toLocaleLowerCase().includes('blog')
            ? 'bg-beef-500'
            : window.location.href
                .toLocaleLowerCase()
                .includes('march-meatness')
            ? 'bg-night-900 d'
            : 'bg-night-800'
        }  z-[90]`}
      >
        {/* Logo */}
        <div
          className="min-h-[48px] min-w-[96px] lg:min-h-[64px] lg:min-w-[192px] mr-[12px] hover:cursor-pointer flex justify-center items-center" // NOTE: negative margin is to account for the christmas logo with the hook on the ornament. Take off when reverting to original logo
          onClick={() => router.push('/')}
        >
          {window.location.href.toLocaleLowerCase().includes('blog') ? (
            <img
              className={`object-contain ${
                pageWidth < 850 ? 'h-[48px] items-center' : 'h-[64px]'
              } `}
              src="/img/rebrand/FullLogo.svg"
              alt="Good Ranchers Blog Logo"
            />
          ) : (
            <img
              className={`object-contain ${
                pageWidth < 926
                  ? 'items-center h-[48px] align-middle '
                  : 'h-[64px]'
              } `}
              src="/img/rebrand/FullLogo.svg"
              alt="Good Ranchers Logo"
            />
          )}
        </div>
        {/* NavBar Items */}
        <div className="h-[64px] flex flex-row items-center justify-end gap-[10px] md:gap-[9px] lg:gap-[15px]">
          {Object.entries(navItems).map(([key, value], index) => {
            return value.show ? (
              <div
                onClick={() => {
                  router.push(value.path)
                }}
                className={`flex flex-col hover:cursor-pointer items-center justify-center ${
                  router.pathname.toLowerCase() === value.path.toLowerCase()
                    ? 'decoration-[3px] underline underline-offset-4 decoration-yolk-500'
                    : 'no-underline'
                }`}
              >
                <span className="font-ringside-compressed-ssm-black font-[900] text-[16px] leading-[16px] lg:text-[18px] xl:text-[20px] lg:leading-[20px] text-center uppercase text-cream-500 hover:text-yolk-500">
                  {value.text}
                </span>
              </div>
            ) : null
          })}

          <MainButton text="Shop Now" />
          {router.pathname.toLowerCase().includes('/blog') ? (
            <BlogCart navbar={true} />
          ) : (
            <CartIcon navbar={true} />
          )}
        </div>
      </div>
    </nav>
  ) : (
    <>
      <div
        className={`w-full h-[48px] p-[8px_16px_8px_16px] flex flex-row fixed ${
          router.pathname.toLocaleLowerCase().includes('blog') ||
          router.pathname.toLocaleLowerCase().includes('pagetitle')
            ? 'bg-beef-500'
            : 'bg-night-800'
        } z-[3]`}
      >
        {/* Logo */}
        <div className="float-left" onClick={() => router.push('/shop')}>
          {' '}
          {/* NOTE: negative margin is to account for the christmas logo with the hook on the ornament. Take off when reverting to original logo */}
          {router.pathname.toLowerCase().includes('blog') ||
          router.pathname.toLocaleLowerCase().includes('pagetitle') ? (
            <img
              className="h-[32px]"
              src="/img/rebrand/FullLogo.svg"
              alt="Good Ranchers Blog"
            />
          ) : (
            <img
              className="h-[32px]"
              src="/img/rebrand/FullLogo.svg"
              alt="Good Ranchers Logo"
            />
          )}
        </div>
        <span className="flex-grow"></span>
        <div className="mt-[6px]">
          <div className="h-[24px] flex flex-row gap-[12px] items-center justify-end p-0">
            {router.pathname.toLowerCase().includes('/blog') ? (
              <BlogCart navbar={true} />
            ) : (
              <CartIcon navbar={true} />
            )}

            <button
              className="bg-transparent border-0"
              type="button"
              id="toggle-drawer"
              onClick={openDrawer}
            >
              <img className="mb-[4px]" src="/img/rebrand/HamburgerMenu.svg" />
            </button>
          </div>
        </div>
      </div>
      {windowWidth <= 926 && (
        <div
          className="fixed top-0 w-screen  md:w-3/4 right-0 h-screen overflow-scroll transform duration-500 translate-x-[100vw] bg-marbling-texture bg-beef-500 bg-cover bg-no-repeat"
          style={{
            transform: drawerOpen ? 'translateX(0)' : '',
            zIndex: 4,
          }}
        >
          <nav>
            <div className="w-full h-[48px] p-[8px_16px_8px_16px] flex flex-row fixed">
              {/* Logo */}
              <div className="float-left" onClick={() => router.push('/shop')}>
                <img
                  className="h-[32px]"
                  src="/img/rebrand/FullLogo.svg"
                  alt="Good Ranchers Logo"
                  onClick={closeDrawer}
                />
              </div>
              <span className="flex-grow"></span>
              <div className="mt-[6px]">
                <div className="h-[24px] flex flex-row gap-[12px] items-center justify-end p-0">
                  <button
                    className="bg-transparent border-0"
                    type="button"
                    id="toggle-drawer"
                    onClick={closeDrawer}
                  >
                    <img
                      className="mb-[4px]"
                      src="/img/rebrand/menuClose.svg"
                    />
                  </button>
                </div>
              </div>
            </div>
          </nav>
          <div className="float-right pr-[16px] w-full flex flex-col items-end gap-[8px] pt-[162px] mt-[75px]">
            {Object.entries(navItems).map(([key, value], index) => {
              return value.show ? (
                <span
                  onClick={() => {
                    router.push(value.path)
                    closeDrawer()
                  }}
                  className="w-full font-knockout-91 font-[415] text-[60px] leading-[45px] text-right uppercase text-cream-500 active:text-yolk-500"
                >
                  {value.text}
                </span>
              ) : null
            })}
            <Link href="/shop">
              <span
                onClick={() => {
                  closeDrawer()
                }}
                className="w-full font-knockout-91 font-[415] text-[60px] leading-[45px] text-right uppercase text-yolk-500"
              >
                Shop Now
              </span>
            </Link>
          </div>
        </div>
      )}
    </>
  )
}

export default Navigation

import Navbar from 'components/Navbar'
import { useScrollPosition, useWindowSize } from 'hooks'
import { useRouter } from 'next/router'
import Link from 'next/link'

export default function HeaderContent() {
  const { pathname } = useRouter()

  return (
    <>
      <header className="block">
        <Navbar />
      </header>
      <div className=""></div>
    </>
  )
}

import { useRouter } from "next/router"
const Hours = () => {
    const router = useRouter()
    
  return (
    <div>
      <span className="text-2xs md:hidden">Monday-Friday | 8am-6pm CST</span>{' '}
      <span className="text-2xs md:hidden">
        <br className="block md:hidden" /> Saturday | 8am-4pm CST
      </span>{' '}
      <span className="text-2xs hidden md:contents">
        <br className="block md:hidden" />Monday-Friday | 8am-6pm CST
      </span>{' '}
      <div>
        <span className="text-2xs hidden md:contents">
          <br className="block md:hidden" />
          Saturday | 8am-4pm CST
        </span>{' '}
      </div>
    </div>
  )
}
export default Hours

import { useWindowSize } from 'hooks'
import Link from 'next/link'

const CallUsBanner = () => {
  const windowSize = useWindowSize()
  const pageWidth = windowSize?.width || 0

  const CTAPage = '/shop'

  return (
    <div
      className={`fixed bg-stone-200 flex justify-center text-center w-full mt-[48px] ${
        pageWidth > 768 ? 'md:mt-[96px]' : 'md:mt-[48px]'
      }  lg:mt-[95px] p-1 z-[90]`}
    >
      <div className="flex">
        <Link href={CTAPage}>
          <div className="cursor-pointer hidden lg:flex font-ringside-ssm-medium  text-grayscale-coal-900 text-3xs md:text-sm no-underline">
            Claim your FREE Wings for a Year - Subscribe & Use Code FREEWINGS
          </div>
        </Link>
        <Link href={CTAPage}>
          <div className="cursor-pointer flex lg:hidden font-ringside-ssm-medium  text-grayscale-coal-900 text-3xs md:text-sm no-underline">
            FREE wings for a year - Code FREEWINGS
          </div>
        </Link>
      </div>
    </div>
  )
}

export default CallUsBanner

import Link from 'next/link'
import React, { useContext } from 'react'
import { UserContext } from 'contexts/user-context'
import Newsletter from './Newsletter/Newsletter'
import { useRouter } from 'next/router'
import Hours from './Hours'

const footerLinks = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'About',
    url: '/about',
  },
  {
    title: 'Help',
    url: '/help-center',
  },
  // {
  //   title: 'Gifting',
  //   url: '/gifting',
  // },
  {
    title: 'Rewards',
    url: '/rewards',
  },
  {
    title: 'Merch',
    url: '/merch',
  },
  {
    title: 'Careers',
    url: ' https://www.linkedin.com/company/good-ranchers-inc/jobs/',
  },
]

const footerLinksRight = [
  {
    title: 'Blog',
    url: '/blog',
  },
  {
    title: 'My Account',
    url: '/account/orders',
  },
  {
    title: 'Shop Now',
    url: '/shop',
  },
  {
    title: 'Sourcing',
    url: '/about#sourcing',
  },
  {
    title: 'Ambassadors',
    url: '/ambassadors',
  },
]

const socialIcons = [
  {
    title: 'Instagram',
    url: 'https://www.instagram.com/goodranchers/',
    icon: '/img/rebrand/instagramIcon.svg',
  },
  {
    title: 'Facebook',
    url: 'https://www.facebook.com/GoodRanchers/',
    icon: '/img/rebrand/facebookIcon.svg',
  },
  {
    title: 'Twitter',
    url: 'https://twitter.com/GoodRanchers',
    icon: '/img/rebrand/twitterIcon.svg',
  },
  {
    title: 'Youtube',
    url: 'https://www.youtube.com/c/GoodRanchers/videos',
    icon: '/img/rebrand/youtubeIcon.svg',
  },
  {
    title: 'TikTok',
    url: 'https://www.tiktok.com/@goodranchers',
    icon: '/img/rebrand/tiktokIcon.svg',
  },
  {
    title: 'Pinterest',
    url: 'https://www.pinterest.com/goodranchers/',
    icon: '/img/rebrand/pinterestIcon.svg',
  },
]

const Footer = () => {
  const { state } = useContext(UserContext)
  const router = useRouter()

  const getBackgroundColor = () => {
    if (
      router.pathname.includes('product') ||
      router.pathname === '/account' ||
      router.pathname === '/shop' ||
      router.pathname === '/about'
    ) {
      return 'bg-cream-300'
    } else {
      return 'bg-white'
    }
  }

  return (
    <div className={`${getBackgroundColor()} `}>
      <div className="background-marbling-dekstop-footer text-cream-400 pt-4 font-ringside-ssm-medium pb-[32px] lg:pb-[96px]">
        {/* @ts-ignore */}
        <div hidden={state.user || router.pathname === '/independenceday'}>
          <Newsletter
            title={
              <>
                Sign Up & Save
                <br />
                <span className="text-[18px] leading-4 font-ringside-ssm-book">
                  Receive the hottest news, tips, and offers fresh off the
                  grill, straight to your inbox.
                </span>
              </>
            }
          />
        </div>
        <div className="flex justify-center md:hidden mt-[64px]">
          {socialIcons.map((socialIcon, index) => (
            <div
              className="mr-[6px] ml-[6px] mb-[32px] cursor-pointer"
              key={index}
            >
              <Link href={socialIcon.url}>
                <a target="_blank" rel="noopener noreferrer">
                  <img src={socialIcon.icon} alt="" />
                </a>
              </Link>
            </div>
          ))}
        </div>
        <div className="flex justify-center lg:pt-[96px]">
          <div className="flex gap-10 md:gap-20">
            <div>
              <div>
                <img
                  onClick={() => router.push('/shop')}
                  className="logo-footer-tw pt-5 hover:cursor-pointer"
                />
                <div className="mt-3 hidden md:flex justify-center md:pt-4">
                  {socialIcons.map((socialIcon, index) => (
                    <div
                      className="mr-[6px] ml-[6px] mb-[32px] cursor-pointer"
                      key={index}
                    >
                      <Link href={socialIcon.url}>
                        <a target="_blank">
                          <img src={socialIcon.icon} alt="" />
                        </a>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div>
              <ul className="footer-list-tw">
                {footerLinks.map((link, index) => (
                  <li key={index} className="pb-3 lg:pb-4">
                    <Link href={link.url}>
                      <a
                        className="footer-list-item-tw"
                        href={link.url}
                        target="_blank"
                      >
                        {link.title}{' '}
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <ul className="footer-list-tw">
                {footerLinksRight.map((link, index) => (
                  <li key={index} className="pb-3 lg:pb-4">
                    <Link href={link.url}>
                      <a className="footer-list-item-tw" href={link.url}>
                        {link.title}
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center gap-[24px] ">
          <img
            className="lg:w-[208px] lg:mr-[810px] left-[185px] top-[-30px] lg:relative "
            src="/img/AccreditedBussines.svg"
            alt=""
          />
          <div className="flex  gap-[11px] lg:gap-[15px] mb-2 lg:mb-7 lg:ml-[270px] lg:absolute">
            <img src="/img/Visa.svg" alt="" />
            <img src="/img/Mastercard.svg" alt="" />
            <img src="/img/Discover.svg" alt="" />
            <img src="/img/AmericanExpress.svg" alt="" />
            <img src="/img/PayPal.svg" alt="" />
          </div>
        </div>
        <div className="flex flex-col text-center justify-center mt-2 lg:pt-[10px]">
          <a
            href="tel:+13464744663"
            className="text-cream-400 no-underline text-2xs"
          >
            <span className="text-2xs">
              Call us: <b>346-474-4663</b>
            </span>{' '}
            <Hours />
          </a>
          <a
            href="mailto:support@goodranchers.com"
            className="font-bold text-cream-400 mt-3 mb-3 md:mt-1 md:mb-1 text-xs font-ringside-ssm-medium"
          >
            {''} support@goodranchers.com
          </a>
        </div>
        <div className="flex justify-center text-cream-700">
          <p className="text-cream-700 uppercase text-4xs">
            <span>{`${String.fromCharCode(169) /* &copy; */} ${new Date(
              Date.now()
            ).getFullYear()} Good Ranchers, LLC.`}</span>
            <Link href="/privacy-policy/">
              <a
                href="/privacy-policy/"
                className="text-cream-700 underline flex justify-center leading-1"
              >
                Privacy Policy
              </a>
            </Link>
            <Link href="/terms-of-use/">
              <a
                href="/terms-of-use/"
                className="text-cream-700 underline flex justify-center leading-1"
              >
                Terms and Conditions
              </a>
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer

import { useWindowSize } from 'hooks'
import CallUsBanner from './CallUsBanner'
import MobileNavigation from './MobileNavigation'
import Navigation from './Navigation'

export default function Navbar() {
  const windowSize = useWindowSize()
  const pageWidth = windowSize?.width || 0
  return (
    <>
      <div className='z-[90]'>{pageWidth < 768 ? <MobileNavigation /> : <Navigation />}</div>
      <CallUsBanner />
    </>
  )
}
